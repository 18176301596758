html {
  font-size: 10px;
}
body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root,
body,
html {
  height: 100%;
}
button {
  background-color: transparent;
  border: none;
}
